<template>
  <div class="mt-4">
    <div class="columns is-2-fullhd">
      <div class="column is-3">
        <aside class="menu">
          <p class="menu-label">
            General
          </p>
          <ul class="menu-list">
            <li>
              <router-link :to="{name: 'a-settings'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/settings') > -1 }">
                Настройки
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'a-products'}" v-bind:class="{ 'is-active' : $route.path.search('\/admin\/products$') != -1 ||  $route.path.search('\/admin\/products\/') != -1}">
                Опубликованные блюда
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-products'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-product-add'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-dishes'}" v-bind:class="{ 'is-active' : $route.path.search('\/admin\/dishes$') != -1 ||  $route.path.search('\/admin\/dish\/') != -1}">
                Номенклатура
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-dishes'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <a href="#" @click="UCS_Sync()"> Обновить </a>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-groups-dishes'}" v-bind:class="{ 'is-active' : $route.path.search('\/admin\/dishes/groups/$') != -1 ||  $route.path.search('\/admin\/dishes\/group\/') != -1}">
                Категории
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-groups-dishes'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <a href="#" @click="UCS_G_Sync()"> Обновить </a>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-modifiers'}" v-bind:class="{ 'is-active' : $route.path.search('\/admin\/modifiers$') != -1 || $route.path.search('\/admin\/modifier\/') != -1}">
                Модификаторы
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-modifiers'}">
                    Список
                  </router-link>
                </li>
              </ul>
            </li>
             <li>
              <router-link :to="{name: 'a-rests'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/rests$') > -1 || $route.path.indexOf('\/admin\/rests\/') > -1}">
                Адреса Ресторанов
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-rests'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <a href="#" @click="RK7_Rests_Sync()"> Обновить </a>
                </li>
                <!-- <li>
                  <router-link :to="{name: 'a-rest-add'}">
                    Добавить
                  </router-link>
                </li> -->
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-clients'}" v-bind:class="{ 'is-active' : $route.path.indexOf('\/admin\/clients') > -1 || $route.path.indexOf('\/admin\/client\/') > -1 }">
                Клиенты
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-clients'}">
                    Список
                  </router-link>
                </li>
<!--                 <li>
                  <router-link :to="{name: 'a-client-add'}">
                    Добавить
                  </router-link>
                </li> -->
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-addresses'}" v-bind:class="{ 'is-active' : $route.path.indexOf('/admin/addresses') > -1 || $route.path.indexOf('\/admin\/address\/') > -1}">
                Адреса
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-addresses'}">
                    Список
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-orders'}" v-bind:class="{ 'is-active' : $route.path.indexOf('\/admin\/orders') > -1 || $route.path.indexOf('\/admin\/order\/') > -1}">
                Заказы
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-orders'}">
                    Список
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <p class="menu-label">
            Administration
          </p>
          <ul class="menu-list">
            <li>
              <router-link :to="{name: 'a-users'}" v-bind:class="{ 'is-active' : $route.path.indexOf('\/admin\/users') > -1 || $route.path.indexOf('\/admin\/user\/') > -1}">
                Пользователи
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-users'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-user-add'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'a-stats'}" v-bind:class="{ 'is-active' : $route.path.indexOf('\/admin\/stats') > -1 || $route.path.indexOf('\/admin\/stats\/') > -1}">
                Статистика
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'a-pushs'}" v-bind:class="{ 'is-active' : $route.path.indexOf('\/admin\/pushs') > -1 || $route.path.indexOf('\/admin\/push\/') > -1}">
                Уведомления
              </router-link>
              <ul>
                <li>
                  <router-link :to="{name: 'a-pushs'}">
                    Список
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'a-push-add'}">
                    Добавить
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
      <div class="column  is-9">
        <div class="container">
          <router-view/>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading" 
      :can-cancel="false"
      loader="bars"
      :is-full-page="true"></loading>
  </div>
</template>

<script>
  
import 'vue-loading-overlay/dist/vue-loading.css';

// import { EventBus } from '@/utils'
// import { ToastProgrammatic as Toast } from 'buefy'

import { DishSync, GroupSync } from '@/api/dish'
import { RestSync } from '@/api/rk7'

export default {
  name: 'Admin',
  components: {
    Loading: () => import('vue-loading-overlay')
  },
  props: {
    //
  },
  data: function () {
    return {
      isLoading: false
    }
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  beforeDestroy: function(){
    //
  },
  created: function() {
    //
  },
  mounted: function(){
    //
  },
  methods: {
    
    UCS_Sync () {
      // let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          DishSync(this.$store.state.jwt_user)   

        }
      })
    },

    UCS_G_Sync () {
      // let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          GroupSync(this.$store.state.jwt_user)   

        }
      })
    },

    RK7_Rests_Sync(){
      // let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          RestSync(this.$store.state.jwt_user)   

        }
      })
    }

  }
}
</script>

<style scoped>
</style>
<style>
.menu {
  text-align: left;
  height: 100hv;
}
</style>
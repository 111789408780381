const axios = require('axios')
// import { EventBus } from '@/utils'

import getUri from "./index"
// import { EventBus } from '@/utils'

export function registerUser (userData) {
  return axios.put(getUri('api/auth/user/register'), userData)
}

export function loginUser (userData) {
  return axios.post(getUri('api/auth/user/login'), userData)
}

// export function userCheck (jwt) {
//   try {
//     return axios.post(getUri(`api/auth/user/check`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
//   } catch (error) {
//     if (error.response){
//       if(error.response.status == 401){
//         EventBus.$emit('logout')
//       }
//       if(error.response.status == 500){
//         EventBus.$emit('error')
//       }
//       else{
//         EventBus.$emit('fail')
//       }
//     }
//     else{
//       EventBus.$emit('fail')
//     }
//     return error
//   }
// }

// export function userLogout (jwt) {
//   try {
//     return axios.post(getUri(`api/auth/user/logout`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
//   } catch (error) {
//     if (error.response){
//       if(error.response.status == 401){
//         EventBus.$emit('logout')
//       }
//       if(error.response.status == 500){
//         EventBus.$emit('error')
//       }
//       else{
//         EventBus.$emit('fail')
//       }
//     }
//     else{
//       EventBus.$emit('fail')
//     }
//     return error
//   }
// }
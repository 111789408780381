const PREFIX = ''

// const DOMAIN = '127.0.0.1'
// const PROTO = 'http'
// const PORT = '5000'

// const PROTO = 'https'
// const DOMAIN = 'simplefood24.duckdns.org/'
// const PORT = '443'

const getUri = (url) => {
  // return `${PROTO}://${DOMAIN}:${PORT}/${PREFIX}${url}`
  return `/${PREFIX}${url}`
}

export default getUri
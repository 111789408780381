<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <router-view/>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '@/utils'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'Auth',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      //
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  beforeDestroy: function(){
    EventBus.$off('errorAuthentication')
    EventBus.$off('failedAuthentication')
    EventBus.$off('successAuthentication')

    EventBus.$off('errorRegistering')
    EventBus.$off('failedRegistering')
    EventBus.$off('successRegistering')
  },
  mounted: function(){
    EventBus.$on('successAuthentication', () => {
      this.$router.push("/")
    })

    EventBus.$on('errorAuthentication', (error) => {
      Toast.open(error)
    })

    EventBus.$on('failedAuthentication', (error) => {
      Toast.open(error)
    })

    EventBus.$on('successRegistering', (error) => {
      Toast.open(error)
      this.$router.push("login")
    })

    EventBus.$on('errorRegistering', (error) => {
      Toast.open(error)
    })

    EventBus.$on('failedRegistering', (error) => {
      Toast.open(error)
    })
  },
  methods: {
    //
  }
}
</script>

<style scoped>
/*html,body {
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  font-weight: 300;
}*/
.hero.is-success {
  background: #F2F6FA;
}
.hero .nav, .hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}

.login-hr{
  border-bottom: 1px solid black;
}

.has-text-black{
  color: black;
}

.field{
  padding-bottom: 10px;
}

.fa{
  margin-left: 5px; 
}
</style>
<style>
</style>
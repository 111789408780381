import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'index',
      meta: { layout: "admin" },
      component: () => import('@/components/admin/Setting.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin', 
      name: 'admin', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/Setting.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/settings', 
      name: 'a-settings', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/Setting.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/users', 
      name: 'a-users', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/users/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/user/:id', 
      name: 'a-user', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/users/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/user/:id/password', 
      name: 'a-user-passwd', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/users/Password.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/add/user', 
      name: 'a-user-add', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/users/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/dishes', 
      name: 'a-dishes', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/dishes/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/dish/:id', 
      name: 'a-dish', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/dishes/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/products', 
      name: 'a-products', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/products/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/product/:id', 
      name: 'a-product', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/products/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/add/product', 
      name: 'a-product-add', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/products/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/pushs', 
      name: 'a-pushs', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/pushs/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/push/:id', 
      name: 'a-push', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/pushs/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/add/push', 
      name: 'a-push-add', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/pushs/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/modifiers', 
      name: 'a-modifiers', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/modifiers/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/modifier/:id', 
      name: 'a-modifier', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/modifiers/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/dishes/groups/', 
      name: 'a-groups-dishes', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/groups/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/dishes/group/:id', 
      name: 'a-group-dishes', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/groups/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/rests', 
      name: 'a-rests', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/rests/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
     { 
      path: '/admin/rests/address/:id', 
      name: 'a-rest', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/rests/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/add/rest', 
      name: 'a-rest-add', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/rests/Add.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/clients', 
      name: 'a-clients', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/clients/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/client/:id', 
      name: 'a-client', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/clients/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/addresses', 
      name: 'a-addresses', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/addresses/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/address/:id', 
      name: 'a-address', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/addresses/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    // { 
    //   path: '/admin/add/address', 
    //   name: 'a-address-add', 
    //   meta: { layout: "admin" },
    //   component: () => import('@/components/admin/addresses/Add.vue'),
    //   beforeEnter (to, from, next) {
    //     if (!store.getters.isAuthenticatedUser) {
    //       next('/login')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    { 
      path: '/admin/stats', 
      name: 'a-stats', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/stats/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/orders', 
      name: 'a-orders', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/orders/Table.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/admin/order/:id', 
      name: 'a-order', 
      meta: { layout: "admin" },
      component: () => import('@/components/admin/orders/Form.vue'),
      beforeEnter (to, from, next) {
        if (!store.getters.isAuthenticatedUser) {
          next('/login')
        } else {
          next()
        }
      }
    },
    { 
      path: '/404', 
      name: '404', 
      meta: { layout: "it-404" },
      component: () => import('@/components/P404.vue'), 
    },
    { 
      path: '/login', 
      name: 'login', 
      meta: { layout: "auth" },
      component: () => import('@/components/Login.vue'), 
    },
    { 
      path: '/signup', 
      name: 'signup', 
      meta: { layout: "auth" },
      component: () => import('@/components/Signup.vue'), 
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { layout: "logout"},
      beforeEnter (to, from, next) {
        store.dispatch("logout")
        next('/')
      }
    },
    { 
      path: '*', 
      redirect: '/404' 
    }
  ]
})
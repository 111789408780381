import Vue from 'vue'  
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist/dist/umd'

const vuexPersist = new VuexPersist({
  key: 'backer-street-gw-admin',
  storage: localStorage
})

// import { registerUser, loginUser, userLogout, userCheck } from '@/api/auth'
import { registerUser, loginUser } from '@/api/auth'
import { isValidJwt, EventBus } from '@/utils'

Vue.use(Vuex)

const state = {  
  user: {},
  jwt_user: '',
}

const actions = {  
  "logout" (context){
    context.commit('logOut')
  },
  // Auth User
  "user.login" (context, userData) {
    context.commit('setUserData', { userData })
    return loginUser(userData)
      .then(response => {
        // context.commit('setJwtUserToken', { jwt: response.data.token })
        context.commit('setJwtUserToken', { data: response.data })
        EventBus.$emit('successAuthentication')
      })
      .catch(error => {
        if (error.response){
          if(error.response.status == 401){
            EventBus.$emit('failedAuthentication', error.response.data.message)
          }
          else{
            EventBus.$emit('errorAuthentication', error.response.data.data)
          }
        }
        else{
          EventBus.$emit('errorAuthentication', error)
        }
      })
  },
  "user.register" (context, userData) {
    // context.commit('setJwtUserToken', { userData })
    return registerUser(userData)
      .then(() => {
          EventBus.$emit('successRegistering')
        }
      )
      .catch(error => {
        if (error.response){
          if(error.response.status == 401){
            EventBus.$emit('failedRegistering', error.response.data.data)
          }
          else{
            EventBus.$emit('errorRegistering', error.response.data.data)
          }
        }
      })
  },
}

const mutations = {  
  setUserData (state, payload) {
    if(payload.userData){
      state.user = payload.userData
    }
    else{
      state.user = payload
    }
  },
  setJwtUserToken (state, payload) {
    state.jwt_user = payload.data.token
  },
  logOut (state) {
    state.user = {}
    state.jwt_user = ''
  },
}

const getters = {  
  isAuthenticatedUser (state) {
    if(state.jwt_user == undefined){
      return false
    }
    return isValidJwt(state.jwt_user)
  },
  getUser(state){
    return state.user
  },
}

export default new Vuex.Store({
  getters: getters,
  mutations: mutations,
  actions: actions,
  state: state,
  plugins: [vuexPersist.plugin]
})
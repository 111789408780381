import Vue from 'vue'
export const EventBus = new Vue()

export function isValidJwt (jwt) {
  if (!jwt || jwt.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
  const now = new Date()
  return now < exp
}

export const limits = [
  5, 10, 20, 25, 50, 75, 100
]

export const LIMITS = [
  10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000
]

export const state_check = [
  {
    id: true,
    name: "Проверять"
  },
  {
    id: false,
    name: "Не проверять"
  }
]

export const state_show = [
  {
    id: true,
    name: "Показывать"
  },
  {
    id: false,
    name: "Не показывать"
  }
]

export const state_yesno = [
  {
    id: true,
    name: "Да"
  },
  {
    id: false,
    name: "Нет"
  }
]
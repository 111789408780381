const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'

export const getDishes = (jwt) => {
  try {
    return axios.post(getUri('api/dishes'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const DishSync = (jwt) => {
  try {
    return axios.post(getUri('api/dishes/sync'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const GroupSync = (jwt) => {
  try {
    return axios.post(getUri('api/groups/sync'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getPageDishes = (page, filters, jwt) => {
  try {
    return axios.post(getUri(`api/dishes?page=${page}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getDish = (id, jwt) => {
  try {
    return axios.post(getUri(`api/dish/${id}`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}
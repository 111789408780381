import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// Beatifull lForm
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faVk } from '@fortawesome/free-brands-svg-icons'
// internal icons
import { faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faPen, faTrash,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faSortDown, faAt, faKey, faAddressCard, faPlay, faBolt,
    faPhone, faBuilding, faMapMarkerAlt, faServer, faSadTear, faCogs, faFileSignature, faUserFriends, faRoute, faVectorSquare,
    faUserShield, faList, faPlus, faUsers, faCopy, faLayerGroup, faHome, faFileImport, faCalendarAlt, faSearchMinus, faSearchPlus, faHandPaper, faHandPointer,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faExclamation, faUser, faShoppingCart, faMapMarkedAlt, faMap, faPiggyBank, faCity,
    faUtensils, faWeight, faEgg, faRubleSign, faSearch, faComments, faEnvelope, faClock, faTimes, faChevronUp, faSync, faShippingFast, faPortrait,
    faCreditCard, faWallet, faArrowDown, faGlobe } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faExclamation, faUser, faBolt,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faShoppingCart, faMapMarkedAlt, faMap, faSortDown, faAt, faKey, faRoute, faVectorSquare,
    faAddressCard, faPhone, faBuilding,faMapMarkerAlt, faServer, faSadTear, faCogs, faFileSignature, faUserFriends, faSearchMinus, faSearchPlus,
    faUserShield, faList, faPlus, faUsers, faCopy, faLayerGroup, faHome, faFileImport, faCalendarAlt, faPen, faTrash, faHandPaper, faHandPointer,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faPlay, faPiggyBank, faCity, faUtensils, faWeight, faEgg, faRubleSign, faSearch, faFacebook, faVk,
    faComments, faEnvelope, faClock, faTimes, faChevronUp, faSync, faShippingFast, faPortrait, faCreditCard, faWallet, faArrowDown, faGlobe);

Vue.component('font-awesome-icon', FontAwesomeIcon);

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
});

// // router
import VueRouter from './router.js'

// layouts
import Admin from "./layouts/Admin.vue";
import Auth from "./layouts/Auth.vue";
import Layout404 from "./layouts/Layout404.vue";
Vue.component("auth-layout", Auth);
Vue.component("it-404-layout", Layout404);
Vue.component("admin-layout", Admin);

const VueInputMask = require('vue-inputmask').default
 
Vue.use(VueInputMask)

import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'

import '@vuikit/theme'

Vue.use(Vuikit)
Vue.use(VuikitIcons)

// MomentJS
import moment from 'moment'
Vue.prototype.$moment = moment
Vue.prototype.$moment.locale('ru')

import store from "@/store"

new Vue({
  render: h => h(App),
  store: store,
  router: VueRouter,
}).$mount('#app')

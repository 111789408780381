<template>
  <div id="app">
    <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link :to="{name: 'index'}" class="navbar-item">
          <img src="@/assets/long-logo.png" width="112" height="28"/>
        </router-link>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link :to="{name: 'index'}" class="navbar-item">
          Home
          </router-link>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons" v-if="!isAuthenticatedUser">
              <router-link :to="{name: 'signup'}" class="button is-primary">
                <strong>Sign up</strong>
              </router-link>
              <router-link :to="{name: 'login'}" class="button is-light">
                Login
              </router-link>
            </div>
            <div class="buttons" v-if="isAuthenticatedUser">
              <router-link :to="{name: 'admin'}" class="button">
                <strong>Admin</strong>
              </router-link>
              <router-link :to="{name: 'logout'}" class="button is-primary">
                <strong>Logout</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <section class="section mt-4">
      <component :is="layout">
        <router-view/>
      </component>
    </section>
  </div>
</template>

<script>
const default_layout = 'admin';

export default {
  name: 'App',
  components: {
    //
  },
  data() {
    return {
      timer: '',
    }
  },
  created () {
    //
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    isAuthenticatedUser(){
      return this.$store.getters.isAuthenticatedUser
    },
  },
  methods: {
    //
  }
}
</script>

<style>
  #app {
    text-align: center;
  }
  .navbar {
    box-shadow: 0 8px 8px rgb(10 10 10 / 10%);
  }
  .app-json{
    text-align: left;
  }
</style>
